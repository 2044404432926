<template>
    <div id="mymes1">
        <div class="mymes1">
            <div class="f_tit basefont30">
            账户信息
        </div>
        <form action="" class="form">
            <div class="f_item">
                <div class="name">昵称</div>
                <div class="f_jut" key="1">
                    <div class="int">
                        <template v-if="nicheng">
                            <input type="text" v-model="form.nickName" placeholder="请输入昵称">
                        </template>
                        <template v-else>
                           <input type="text" class="fzname" v-model="form.nickName" placeholder="请输入昵称">
                        </template>
                        <!-- <a href="javascript:;" class="xiu" @click="usenameFocus"><img src="../../assets/images/xiugaiicom1.png" alt=""></a> -->
                    </div>
                    
                </div>
            </div>
            <div class="f_item f_radio">
                <div class="name">性别</div>
                <div class="f_jut">
                    <label><input type="radio"  name="xinbie" v-model="form.userSex" value="男"><span></span><i>男</i></label>
                    <label><input type="radio" name="xinbie" v-model="form.userSex" value="女"><span></span><i>女</i></label>
                </div>
            </div>
            <!-- <div class="f_item">
                <div class="name">电子邮箱</div>
                <div class="f_jut " key="2">
                    <div class="int">
                        <template v-if="eml">
                            <input type="text" disabled v-model="form.userEmail" placeholder="请输入邮箱">
                        </template>
                        <template v-else>
                           <input type="text" class="fzeml" v-model="form.userEmail" placeholder="请输入邮箱">
                        </template>
                        <a href="javascript:;" class="xiu" @click="emaill"><img src="../../assets/images/xiugaiicom1.png" alt=""></a>
                    </div>
                </div>
            </div> -->
            <div class="f_item f_file">
                <div class="name">上传头像</div>
                <div class="f_jut">
                    <div class="img">
                        <img :src="form.headerImg" v-show="imgShow" alt="">
                    </div>
                    <a href="javascript:;" class="chuan">
                        <span>+上传</span>
                        <input type="file" id="upload" name="tou">
                    </a>
                </div>
            </div>
            <!-- <div class="f_item leixi">
                <div class="name">
                    <p>爱好类型</p>
                    <span>(可多选)</span>
                </div>
                <div class="f_jut">
                    <template v-for="(item,index) in aihao">
                        <div :class="{item:true,on: item.pan}" :key="index" @click="nei(index)">
                            <div class="list">
                                <div class="img">
                                    <img :src="item.url" :alt="item.title" >
                                </div>
                                <div class="tit">{{item.title}}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div> -->
            <div class="f_item f_but">
                <div class="name"></div>
                <div class="f_jut">
                    <button type="submit" @click.prevent="mesgSubmit()" class="hm-but f_sub">
                        <span>保存修改</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>
</template>

<script>
import { mapState  } from 'vuex';
const OSS = require('ali-oss');
export default {
    name: "Mymessage",
    data(){
        return {
            //swiper
            nicheng:true,
            eml: true,
            aihao:[
                {
                    url: require('../../assets/images/meixiimf1.png'),
                    title: "美系",
                    pan: true,
                },
                {
                    url: require('../../assets/images/meixiimf2.png'),
                    title: "日系(JDM)",
                    pan: false,
                },
                {
                    url: require('../../assets/images/meixiimf3.png'),
                    title: "街头",
                    pan: true,
                },
                {
                    url: require('../../assets/images/meixiimf4.png'),
                    title: "竞技",
                    pan: false,
                }
            ],
            uploadToken: {}
        }
    },
    computed:{
        ...mapState ({
            // form: 'getuserConten',
            form: state => state.UserInfo.userConten,
        }),
        imgShow(){
            return this.form.headerImg
        }
    },
    created:function(){
        
    },
    mounted:function(){
        var _this = this;
        $(".f_file input[type=file]").change(function(){
                // var imgSrc = _this;
                var imgSize = this.files[0].size;  //b
                if(imgSize>1024*1024*10){//10M
                    return this.$message.error('上传图片不能超过1M');
                }
                if(this.files[0].type != 'image/png' && this.files[0].type != 'image/jpeg' && this.files[0].type != 'image/gif'){
                    return this.$message.error('图片上传格式不正确');
                }
                
                // $(this).parents(".f_file").find(".img img").show();
                // // _this.form.headerImg=_this.getObjectURL($(this).get(0).files[0]);
                // $(this).parents(".f_file").find(".img img").attr("src",_this.getObjectURL($(this).get(0).files[0]));
        });
        /* 获取上传OSStoken */
        let client;
        _this.$axios.post('/api2/portal/storage/file/fileInfo/getToken').then(res=>{
            this.uploadToken = res.data;
             client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: this.uploadToken.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: this.uploadToken.accessKeyId,
                accessKeySecret: this.uploadToken.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: this.uploadToken.securityToken,
                // 填写Bucket名称。
                bucket: this.uploadToken.bucketName
            });
        }).catch(error=>{
            console.log(error);
        });
        

      // 从输入框获取file对象，例如<input type="file" id="file" />。
      let data;
      // 创建并填写Blob数据。
      //const data = new Blob(['Hello OSS']);
      // 创建并填写OSS Buffer内容。
      //const data = new OSS.Buffer(['Hello OSS']);

      const upload = document.getElementById("upload");

       async function putObject (data,str1) {
         try {
           // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
           // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
           // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
          const result = await client.put(
            str1,
            data
            //{headers}
          );
          console.log(result);
          _this.form.headerImg=result.url;
        } catch (e) {
          console.log(e);
        }
      }

      upload.addEventListener("change", () => {
        data = document.getElementById("upload").files[0];
        var in1 = data.name.lastIndexOf(".");
        var str1 = data.name.substring(in1);
        var date = "gzc" + new Date().getTime() + str1;
        putObject(data,date);
      });
    },
    methods: {
        /* 邮箱自动获取焦点 */
        emaill(){
            this.eml=!this.eml;
            if(this.eml==false){
                setTimeout(()=>{
                    document.querySelector(".fzeml").focus();
                },200);
            }
        },
        /* 昵称自动获取焦点 */
        usenameFocus(){
            this.nicheng=!this.nicheng
            if(this.nicheng==false){
                setTimeout(()=>{
                    document.querySelector(".fzname").focus();
                },200);
            }
        },
        /* 修改账户信息 */
        async mesgSubmit(){
            console.log(this.form);
            // this.form.headerImg = $(".f_file input[type=file]").get(0).files[0];
            // console.log(this.form.headerImg);
            await this.$axios.post('/api2/portal/auth/user/modifyBasicInfo',{
                "nickName":this.form.nickName,
                "userSex": this.form.userSex,
                "birthday":this.form.birthday,
                "headerImg": this.form.headerImg,
                "addressId":this.form.addressId,
                "homePage":this.form.homePage,
                "userAge":this.form.userAge,
                // userEmail: this.form.userEmail,
            }).then(res=>{
                console.log(res);
                if(res.code="000000"){
                    this.$message({
                        message: '修改成功！',
                        type: 'success'
                    });
                }else{
                    his.$message({
                        message: '修改失败！',
                        type: 'error'
                    });
                }
                
            }).catch(error=>{
                console.log(error);
            });

            const token = this.$store.state.loginStore.login.token;
            if(token){
                this.$axios.post('/api2/portal/auth/user/getUserInfo').then(res=>{
                        // console.log("账户信息",res);
                        this.$store.state.UserInfo.userConten=res.data;
                        this.$store.state.UserInfo.accountMessage = {...res.data};
                }).catch(error=>{
                        console.log(error);
                });
            }
            
        },
        nei:function(index){
            this.aihao[index].pan=!this.aihao[index].pan;
        },
        file1:function(){
            var file = document.getElementById("zhidao").files[0];
            this.form.zhizhao=file;
        },
        getObjectURL:function(file){
            var url = null ;
            if (window.createObjectURL!=undefined) { // basic
                url = window.createObjectURL(file) ;
            } else if (window.URL!=undefined) { // mozilla(firefox)
                url = window.URL.createObjectURL(file) ;
            } else if (window.webkitURL!=undefined) { // webkit or chrome
                url = window.webkitURL.createObjectURL(file) ;
            }
            return url;
        },
    }
}
</script>
